export default defineNuxtRouteMiddleware(async (to, from) => {
  // check if the user can access this organisation
  const { user } = useUser();
  const organisationId = to.params.organisationId;

  console.log('user', user);

  console.log('organisationId', organisationId);

  if (!user.organisationIds?.find((org) => org.id === organisationId)) {
    return abortNavigation(
      createError({
        statusCode: 403,
        message: 'You do not have access to this organisation.',
      })
    );
  }
});